<template>
<!-- 中间 -->
    <div>
		<div class="container">
			<div class="contentItem lightBack" v-for="(item,index) in list" :key="index">
        		<img class="img" :src="item.img" >
				<div style="display: flex;flex-direction: column;">
					<div class="whiteFont white_num" style="width:0.96rem;">
						<countTo :startVal='item.preNum' :endVal='item.num' :duration='3000'></countTo>
					</div>
					<div class="whiteFont white_text">{{item.title}}</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	// 引入数字滚动组件
import countTo from 'vue-count-to';
import { getSocket } from '../../../assets/js/websocket'
  export default {
    components: { countTo },
	data() {
		return { 
			list:[
				{ 
					title:'注册人数' , 
					num:0 , 
					img:require('../../../assets/images/running.png'),
					preNum:0
				},
				{ 
					title:'今日运动人数' ,
					num:0, 
					img:require('../../../assets/images/num.png'),
					preNum:0
				},
				{ 
					title:'运动人数' , 
					num:0,
					img:require('../../../assets/images/average.png'),
					preNum:0
				},
				{ 
					title:'运动时长(时)' ,
					num:0,
					img:require('../../../assets/images/people.png'),
					preNum:0
				},
			],
			topTimer:"",
		}
	},
	methods: {
		getData(){
			let that = this;

			let url = window.appHost+"/api/v1/screen/App_vital/vital"
			let result = window.ajaxRequest(url,"","post")
			// console.log(result)
			that.list[0].preNum = that.list[0].num;
			that.list[1].preNum = that.list[1].num;
			that.list[2].preNum = that.list[2].num;
			that.list[3].preNum = that.list[3].num;
			that.list[0].num = result.msg.appUserCount;
			that.list[1].num = result.msg.day_sportsNum;
			that.list[2].num = result.msg.all_sportsNum;
			that.list[3].num = result.msg.timeNum;
		}	
	},
	mounted () {
		let that = this;
		that.getData();
		that.topTimer = setInterval(() => {
			that.getData();
		}, 3000);		
	},
	beforeDestroy(){
		clearInterval(this.topTimer)
	}
}
</script>

<style scoped>
	.container{
		width: 7.82rem;
		height: 0.81rem;
		display: flex;
		justify-content: space-around;
		align-items: center;
		position: relative;
	}
	.contentItem{
		width: 1.8rem;
		height: 0.81rem;
		display: flex;
		justify-content: flex-start;
    	box-sizing: border-box;
		align-items: center;
    	padding: 0rem 0.1rem;
		
	}
  .lightBack {
    background: linear-gradient(272deg,rgba(8,8,9,0.21) 0%, #0f0c0c 100%);
    box-sizing: border-box;
  }
  .img{
    width: 0.33rem;
    height: 0.34rem;
    margin: 0.1rem;
	margin-right: 0.1rem;
  }
  .white_num{
      font-size:0.16rem;
      color:#08A4FD;
      font-family: HighlandGothicFLF;
      text-align: center;
  }
  .white_text{
      font-size:0.12rem;
      text-align: center;
  }
    @media screen and (max-width: 1000px) {
        .white_num{
            width:120%;
            transform: scale(0.8);
            margin-left:-0.1rem;
        }
        .white_text{
            width:160%;
            transform: scale(0.7);
            margin-left:-0.3rem;
        }
    }

</style>